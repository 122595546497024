import logo_dark from "../icons/yomicall_light.png";
import logo_light from "../icons/yomicall_dark.png";
import Y from "../icons/yomicall_Y.png";
import platform from "../icons/platform.png";

const Icons = {
    logo_dark,
    logo_light,
    Y,
    platform
}

export default Icons;