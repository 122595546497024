import React from "react";
import "./three.scss";
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import CellTowerRoundedIcon from '@mui/icons-material/CellTowerRounded';
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';

const Three = () => {
    return (
        <div className="three">
            <div className="content">
                <span className="title">Meet <span className="blue">Yomicall</span>: Your Best Friend for Customer Calls</span>
                <span className="subtitle">Yomicall uses cutting-edge AI technology to transcribe, analyze, and optimize customer calls from and to your CRM. It ensures script adherence, highlights behavioral trends, and generates personalized customer profiles to help your team make better decisions faster.</span>

                <div className="boxes">
                    <div className="row">
                        <div className="box">
                            <div className="op">
                                <InsightsRoundedIcon className="icon"/>
                                <span className="main">Automated Call Analysis</span>
                            </div>
                            <span className="text">Transcription and approximately real-time insights post-call.</span>
                        </div>
                        <div className="box">
                            <div className="op">
                                <CellTowerRoundedIcon className="icon"/>
                                <span className="main">Script Compliance Monitoring</span>
                            </div>
                            <span className="text">Track adherence and performance over time.</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="box">
                            <div className="op">
                                <TipsAndUpdatesRoundedIcon className="icon"/>
                                <span className="main">Behavioral Insights</span>
                            </div>
                            <span className="text">Identify trends and customer preferences for better responses.</span>
                        </div>
                        <div className="box">
                            <div className="op">
                                <PictureAsPdfRoundedIcon className="icon"/>
                                <span className="main">Effortless Reporting</span>
                            </div>
                            <span className="text">Generate detailed, actionable Customer Profiles from each call as PDF or Web-page with no manual effort.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Three;
