import React from "react";
import "./header.scss";
import Icons from "../../icons/Icons";

const Header = () => {
    return (
        <div className="header">
            <img src={Icons.logo_light} alt="icon" className="icon" />

            <div className="mid">
                <span className="item">Home</span>
                <span className="item">Solutions</span>
                <span className="item">Docs</span>
                <span className="item">Pricing</span>
            </div>
            <div className="buttons">
                <span className="btn">Join the waitlist</span>
            </div>
        </div>
    )
}

export default Header;