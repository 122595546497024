import React from "react";
import "./main.scss";
import Header from "../header/Header";
import One from "../One/One";
import Two from "../Two/Two";
import Footer from "../footer/Footer";
import Three from "../Three/Three";
import HelmetComponent from "../Helmet/Helmet";


const Main = () => {
    return (
        <div className="main">
            <HelmetComponent title="Yomicall"/>
            <Header />
            <One />

            <Three />
            <Footer />
        </div>
    )
}

export default Main;