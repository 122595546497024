import React from "react";
import "./one.scss";
import Icons from "../../icons/Icons";

const One = () => {
    return (
        <div className="one">
            <div className="content">
                <span className="badge">Waitlist is available now</span>
                <div className="col">
                    <span className="title">AI-Driven Process Optimization for</span>
                    <span className="title">Customer Interactions</span>

                </div>

                <div className="subcol">
                    <span className="subtitle">Transform how your business handles customer interactions with</span>
                    <span className="subtitle"> AI-powered automation for call centers and sales teams.</span>
                </div>

                <span className="btn">Get early access now</span>

                <img src={Icons.platform} alt="icon" className="image" />
            </div>
        </div>
    )
}

export default One;